<template>
  <div class="profile-communities v-step-communities-section">
    <div class="title">
      <h1>{{ $t('profile.communities.title') }}</h1>
    </div>
    <div class="content col-all-12">
      <div class="flexbox">
        <div class="left col-pc-6 col-mobile-12">
          <div class="subtitle">
            <h3>{{ $t('profile.communities.owned-communities') }}</h3>
          </div>
          <div class="boxes">
            <ul>
              <li class="community-box col-mobile-12" v-for="community in ownedCommunities" :key="community.id">
                <div class="infos col-pc-12 col-mobile-10">
                  <img :src="community.logo" alt="community icon" style="width: 80px; height: 80px;" />
                  <div class="text col-mobile-11">
                    <div class="community-title">
                      <h3>{{ community.name }}</h3>
                    </div>
                    <div class="details">
                      <ul>
                        <li class="members-count">
                          <i class="fas fa-user-friends"></i>
                          <span>
                            {{
                              community.members.filter(
                                member => !(nodeEnv === 'development' && member.user.email.includes('leafcad'))
                              ).length
                            }}
                          </span>
                        </li>
                        <li class="extensions-count">
                          <i class="fas fa-cubes"></i>
                          <span>0</span>
                        </li>
                        <li class="community-tier">
                          <span>{{ community.paymentData.plan.name }}</span>
                        </li>
                      </ul>
                      <p class="payment-frequency">
                        {{ $t('profile.communities.payment-cycle.' + community.paymentData.plan.cycle) }}
                      </p>
                      <h4
                        class="expiration"
                        :uk-tooltip="
                          'title: ' +
                            (community.paymentData.autoRenew
                              ? $t('profile.communities.renew-on')
                              : $t('profile.communities.expire-on')) +
                            ' ' +
                            moment(community.paymentData.expiresAt).format('YYYY-MM-DD') +
                            ';' +
                            'pos: bottom-left'
                        "
                      >
                        {{
                          community.paymentData.autoRenew
                            ? $t('profile.communities.renew')
                            : $t('profile.communities.expire')
                        }}
                        {{ moment(community.paymentData.expiresAt).fromNow() }}
                      </h4>
                    </div>
                  </div>
                  <div class="ellipsis">
                    <a class="ellipsis-button">
                      <i class="fas fa-ellipsis-v"></i>
                    </a>
                    <div class="ellipsis-menu" uk-dropdown="pos: bottom-right;mode: click;">
                      <a href="#" class="manage col-pc-12">
                        <div class="icon col-pc-2">
                          <i class="fab fa-cc-stripe"></i>
                        </div>
                        <div class="b-text col-pc-10">
                          <span @click="redirectToPortal(community.paymentData.id)">
                            {{ $t('profile.communities.manage-subscription') }}
                          </span>
                        </div>
                      </a>
                      <a href="#" class="edit col-pc-12" @click="editCommunity(community.id)">
                        <div class="icon col-pc-2">
                          <i class="fas fa-cog"></i>
                        </div>
                        <div class="b-text col-pc-10">
                          <span>{{ $t('profile.communities.edit') }}</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <!-- <a class="create community-box noselect disabled" @click="createCommunity"> -->
              <a class="create community-box noselect disabled" :disabled="true">
                <i class="fas fa-plus col-pc-2"></i>
                <span class="col-pc-auto">{{ $t('profile.communities.create-a-new-community') }}</span>
              </a>
            </ul>
          </div>
        </div>
        <div class="right col-pc-6 col-mobile-12">
          <div class="subtitle">
            <h3>{{ $t('profile.communities.joined-communities') }}</h3>
          </div>
          <div class="boxes">
            <ul>
              <li class="community-box" v-for="communityMember in filteredJoinedCommunities" :key="communityMember.id">
                <div class="infos col-pc-12 col-mobile-10">
                  <img :src="communityMember.community.logo" alt="community icon" style="width: 80px; height: 80px;" />
                  <div class="text">
                    <div class="community-title">
                      <h3>{{ communityMember.community.name }}</h3>
                    </div>
                    <div class="details">
                      <ul>
                        <li class="members-count">
                          <i class="fas fa-user-friends"></i>
                          <span>
                            {{
                              communityMember.community.members.filter(
                                member => !(nodeEnv === 'production' && member.user.email.includes('leafcad'))
                              ).length
                            }}
                          </span>
                        </li>
                        <li class="community-fonction">
                          <span>
                            {{ $t('profile.communities.rank.' + getHighestRank(communityMember.permissions)) }}
                          </span>
                        </li>
                      </ul>
                      <p class="payment-frequency">{{ $t('profile.communities.since') }}</p>
                      <h4 class="expiration">{{ moment(communityMember.createdAt).format('YYYY-MM-DD') }}</h4>
                    </div>
                  </div>
                </div>
                <div class="ellipsis">
                  <a class="ellipsis-button">
                    <i class="fas fa-ellipsis-v"></i>
                  </a>
                  <div class="ellipsis-menu" uk-dropdown="pos: bottom-right;mode: click;">
                    <a
                      href="#"
                      class="edit col-pc-12"
                      @click="leaveCommunity(communityMember.id, communityMember.community.name)"
                    >
                      <div class="icon col-pc-2">
                        <i class="fas fa-door-open"></i>
                      </div>
                      <div class="b-text col-pc-10">
                        <span>{{ $t('profile.communities.leave') }}</span>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              <a class="join community-box" @click="joinCommunity">
                <i class="fas fa-plus col-pc-2"></i>
                <span class="col-pc-auto">{{ $t('profile.communities.join-a-community') }}</span>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
export default {
  name: 'general',
  data() {
    return {
      joinedCommunities: [],
      ownedCommunities: [],
      nodeEnv: process.env.NODE_ENV
    }
  },
  apollo: {
    joinedCommunities: {
      query: require('@/graphql/user/fetchJoinedCommunities.gql'),
      update: data => data.me.communities,
      fetchPolicy: 'no-cache'
    },
    ownedCommunities: {
      query: require('@/graphql/user/fetchOwnedCommunities.gql'),
      update: data => data.me.ownedCommunities,
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    filteredJoinedCommunities() {
      return this.joinedCommunities.filter(
        community => !this.ownedCommunities.some(ownedCommunity => ownedCommunity.id === community.community.id)
      )
    }
  },
  methods: {
    getHighestRank(permissions) {
      const values = permissions.map(permission => permission.name)

      if (values.includes('admin')) {
        return 'admin'
      }

      return 'member'
    },
    redirectToPortal(subscriptionId) {
      this.$store
        .dispatch('profile/createPortalSession', { subscriptionId, returnUrl: window.location.href })
        .then(url => window.location.replace(url))
    },
    editCommunity(communityId) {
      const community = this.$store.state.selection.communities.filter(
        community => community.community.id === communityId
      )[0]

      if (!community) return

      this.$store.commit('selection/community', community)
      this.$store.dispatch('selection/selectCommunity').then(() => {
        this.$router.push({ name: 'admin-general' })
      })
    },
    createCommunity() {
      this.$store
        .dispatch('auth/createAuthToken')
        .then(response => {
          this.isLoading = false

          const url = new URL('https://leafcad.com/' + this.$i18n.locale + '/checkout')
          url.searchParams.append('a-token', response.token)

          window.location.replace(url.toString())
        })
        .catch(error => {
          this.isLoading = false
          console.error(error)

          error.graphQLErrors.map(({ extensions }) => {
            this.$store.dispatch('errorManager/showError', { error: Object.values(extensions)[0] })
          })
        })
    },
    joinCommunity() {
      this.$swal({
        title: this.$t('selection.communities.join-a-community'),
        input: 'text',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: result => {
          return this.$store
            .dispatch('selection/joinCommunity', result)
            .then(() => {
              return this.$apollo.queries.joinedCommunities.refetch().then(() => {
                return true
              })
            })
            .catch(error => {
              this.isLoading = false
              console.error(error)

              error.graphQLErrors.map(({ extensions }) => {
                this.$store.dispatch('errorManager/showError', { error: Object.values(extensions)[0] })
              })

              return false
            })
        }
      })
    },
    leaveCommunity(communityMemberId, communityName) {
      this.$swal({
        title: this.$t('profile.communities.leave-community-dialog.title', { communityName }),
        text: this.$t('profile.communities.leave-community-dialog.text', { communityName }),
        type: 'warning',
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: this.$t('profile.communities.leave-community-dialog.confirm'),
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.$store
            .dispatch('profile/leaveCommunity', communityMemberId)
            .then(() => {
              return this.$apollo.queries.joinedCommunities.refetch().then(() => {
                return true
              })
            })
            .catch(error => {
              this.isLoading = false
              console.error(error)

              error.graphQLErrors.map(({ extensions }) => {
                this.$store.dispatch('errorManager/showError', { error: Object.values(extensions)[0] })
              })

              return false
            })
        }
      })
    }
  }
}
</script>
